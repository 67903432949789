@use "base/media" as *;
@use "base/_mixins" as *;

// @section: Dialog
//

mat-dialog-container.mat-dialog-container {
  min-width: 400px;
  max-width: 600px;
  max-height: 100%;
  overflow: auto;
  background: var(--base-card);
  border-radius: 8px;
  outline: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
  box-sizing: border-box;

  .place-order-dialog & {
    max-width: 470px;
    padding: 10px;

    @include media("<=sm") {
      height: auto;
      overflow-y: auto;
    }

    .moex-place-order {
      padding: 0;
    }
  }

  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -24px -24px 0;
    padding: 15px;
    font-size: 16px;
    color: var(--base-text);
    border: none;

    .mat-dialog-title {
      margin: 0;
    }
  }

  .mat-dialog-content {
    margin: 0 -24px;
    padding: 15px;
    font-weight: 400;
  }

  .mat-dialog-actions {
    margin: 0 -24px;
    padding: 24px 15px 0;
  }

  @include media("<=xs") {
    width: 100%;
    min-width: 250px;
  }
}

.subscribe-dialog {
  height: 100%;
  max-width: 92vw !important;
  
  mat-dialog-container.mat-dialog-container {
    padding: 0;
    max-height: 95vh;
    margin: auto;
    height: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    
    .dialog-header,
    .mat-dialog-content,
    .mat-dialog-actions {
      margin: 0;
    }
  }
}

[mat-dialog-close]:focus,
[mat-dialog-close] {
  padding: 5px;
  color: var(--base-muted);
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  @include rtl-prop("margin-left", "margin-right", 5px, 0);

  &:hover {
    color: var(--base-accent);
  }

  app-icon {
    @include square(10px);
    margin: auto;
  }
}

.personal-info-dialog .mat-dialog-container {
  max-height: inherit;
}


.place-order-dialog .moex-place-order .disabled-overlay {
  margin-left: -24px;
  margin-right: -24px;

  @include media("<=md") {
    top: 140px;
  }
}

.eqwire-payment-dialog {
  width: 660px;

  mat-dialog-container.mat-dialog-container {
    width: 100%;
    max-width: 660px;
  }
}

.transactions-filter-dialog {
  overflow-y: auto;

  .mat-dialog-container {
    min-width: auto;
  }
}

.pbsr-place-order-send mat-dialog-container.mat-dialog-container .mat-dialog-actions {
  gap: 8px;

  button {
    margin: 0;
  }

  @include media("<=xs") {
    flex-direction: column;
   
    button {
      flex: 1;
      width: 100%;
    }
  }
}
